import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './app.scss'
import Vuelidate from 'vuelidate'
import 'es6-promise/auto'
import store from './store/index'
import router from './router/index'
import i18n from './i18n'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  i18n,
  router
}).$mount('#app')
