<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');
#app {
  font-family: 'Roboto', sans-serif;
  color: #FFFFFF;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Roboto Slab', serif;
}
p{
  font-family: Roboto, sans-serif;
}
</style>