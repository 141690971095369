<template>
        <b-row class="align-items-center">
            <b-col md style="margin-bottom: 20px">
                <Setup/>
            </b-col>
            <b-col md>
                <h2>{{ $t('Body.Card_G.Title') }}</h2>
                <p><b>{{ $t('Body.goal') }}</b>{{ $t('Body.Card_G.goal') }}</p>
                <ul class="checklist align-items-center" >
                <li><p>{{ $t('Body.Card_G.bulletone') }}</p></li>
                <li><p>{{ $t('Body.Card_G.bullettwo') }}</p></li>
                <li><p>{{ $t('Body.Card_G.bulletthree') }}</p></li>
                <router-link :to="'/access'" style="text-decoration: inherit">{{ $t('EarlyaccessLink') }}&rarr;</router-link>
                </ul>
            </b-col>
        </b-row>
</template>

<script>
import Setup from '../components/Setup.vue'

export default {
  components: { Setup },
    name: 'Card',
}
    
</script>

<style scoped>
.checklist{
    margin-left: 40px;
    list-style-image: url('../assets/check.svg');
}

p{
    font: inherit;
    font-size: 20px;
}

.placeholder{
    height: 50px;
}

@media only screen and (max-width: 992px) {
  .placeholder{
      height: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .placeholder{
      height: 30px;
  }
}
</style>