<template>
    <div id="app" style="min-height: 100vh;">
      <Header style="height 55px"/>
      <div style="height: 10vh; background-color: rgba(8, 30, 85, 0.2)"/>
      <div style="background-color: rgba(8, 30, 85, 0.2)">
      <About style="min-height: calc(100vh - 16vh - 235px);"/>
      </div>
      <div style="height: 5vh; background-color: rgba(8, 30, 85, 0.2)"/>
    <center style=" margin-top: 1vh">
          <h2>{{$t('Slogan')}}</h2>
    </center>
      <Footer style="align: bottom"/>
  </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import About from '../components/About'

export default {
    name: 'access',
    components: {
        Header,
        Footer,
        About
    }
}
</script>