<template>
    <b-container style="height: 100%;">
        <h1>{{$t('Notfound.title')}}<b>{{$t('Notfound.boldtitle')}}</b></h1>
        <center>
            <div  class="box">
                <br />
                <h1 class="error"><b>404</b></h1>
                <br />
                <h4>{{$t('Notfound.message')}}</h4>
                <br />
                <br />
                <div style="margin-left:25px; margin-right: 25px">
                    <router-link :to="'/'" style="text-decoration: inherit"><b-button variant="primary" block>{{$t('Notfound.Button')}}</b-button></router-link>
                </div>
            </div>
        </center>
    </b-container>
</template>

<script>

export default {
    name: "Hero"
}
</script>

<style lang="scss">
.box{
    background-color: white;
    border-radius: 20px;
    max-width: 500px;
    height: 300px;
    box-shadow: 6.25px 12.5px 100px rgba(8,30,85,0.25);
}
.error{
    font-size: 70px;
    text-shadow: 0px 0px 20px rgba(215,36,131,0.25);
}
</style>
