<template>
    <b-card>
        <b-row>
            <b-col>
                <h4>{{$t('App.Evaluation')}}</h4>
            </b-col>
        </b-row>
        <h5>{{gettitle(mtitle)}}</h5>
        <component  :is='currentView' v-bind="{tdata: this.$store.getters.gettdata, filldata: this.filldata, teacherdata: this.teacherdata.concat(this.$store.getters.gettdata), topic: [this.$store.getters.gettopicOne, this.$store.getters.gettopicTwo, this.$store.getters.gettopicThree]}" style="bottom-padding: 50px"/>
        <b-row style="text-align: center align-content: center; text-align: center;" no-gutters>
            <b-col>
                <b-link @click="containerchange(1)" class="p">
                    <b-img fluid :src="getpic(overviewpic)"/>
                    <p v-bind:class="[(mtitle!=0) ? 'p' : 'pactive' , 'p']">{{$t('App.Overview')}}</p>
                </b-link>
            </b-col>
            <b-col>
                <b-link @click="containerchange(2)" class="p">
                    <b-img fluid :src="getpic(topicOnepic)"/>
                    <p v-bind:class="[(mtitle!=1) ? 'p' : 'pactive' , 'p']">{{this.$store.getters.gettopicOne}}</p>
                </b-link>
            </b-col>
            <b-col>
                <b-link @click="containerchange(3)" class="p">
                    <b-img fluid :src="getpic(topicTwopic)"/>
                    <p v-bind:class="[(mtitle!=2) ? 'p' : 'pactive' , 'p']">{{this.$store.getters.gettopicTwo}}</p>
                </b-link>
            </b-col>
            <b-col>
                <b-link @click="containerchange(4)" class="p">
                    <b-img fluid :src="getpic(topicThreepic)"/>
                    <p v-bind:class="[(mtitle!=3) ? 'p' : 'pactive' , 'p' ]">{{this.$store.getters.gettopicThree}}</p>
                </b-link>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import Radar from '../components/Radar'
import TableLine from '../components/Line'

export default {
    components: { 
        Radar,
        TableLine
    },
    name: 'Feedback',
    data(){
        return {
            mtitle: 0,
            currentView: Radar,
            tdata: '',
            overviewpic: "overview_a",
            topicOnepic: 'topicOne_n',
            topicTwopic: 'topicTwo_n',
            topicThreepic: 'topicThree_n',
            filldata: [],
            teacherdata: []
        }
    },mounted(){
        this.containerchange(1)
        this.calctdata()
    },
    methods:{
        calctdata(){
            this.tdata = this.$store.getters.gettdata
        },
        containerchange(num){
            switch(num) {
            case 1:
                this.currentView = Radar
                this.overviewpic = 'overview_a'
                this.topicOnepic = 'topicOne_n'
                this.topicTwopic = 'topicTwo_n'
                this.topicThreepic = 'topicThree_n'
                this.mtitle = 0
                break;
            case 2:
                this.currentView = TableLine
                this.overviewpic = 'overview_n'
                this.topicOnepic = 'topicOne_a'
                this.topicTwopic = 'topicTwo_n'
                this.topicThreepic = 'topicThree_n'
                this.mtitle = 1
                this.filldata = [2,3,3,3]
                this.teacherdata = [2,3,4]
                break;
            case 3:
                this.currentView = TableLine
                this.overviewpic = 'overview_n'
                this.topicOnepic = 'topicOne_n'
                this.topicTwopic = 'topicTwo_a'
                this.topicThreepic = 'topicThree_n'
                this.filldata = [4,4,5,3]
                this.teacherdata = [3,2,3,4]
                this.mtitle = 2
                break;
            case 4:
                this.currentView = TableLine
                this.overviewpic = "overview_n"
                this.topicOnepic = 'topicOne_n'
                this.topicTwopic = 'topicTwo_n'
                this.topicThreepic = 'topicThree_a'
                this.filldata = [5,4,5,4]
                this.teacherdata = [3,3,4,3]
                this.mtitle = 3
                break;
            default:
                this.currentView = Radar
                this.overviewpic = 'overview_a'
                this.topicOnepic = 'topicOne_n'
                this.topicTwopic = 'topicTwo_n'
                this.topicThreepic = 'topicThree_n'
            }

        },
         getpic(pic){
            return require("../assets/"+pic+".svg")
        },
        gettitle(mtitle){
            var val
            switch(mtitle) {
            case 0:
                val = this.$t('App.Overview')
                break;
            case 1:
                val = this.$store.getters.gettopicOne
                break;
            case 2:
                val = this.$store.getters.gettopicTwo
                break;
            case 3:
                 val = this.$store.getters.gettopicThree
                break;
            default:
                val = this.$t('App.Overview')
            }
            return val
        },
    }
}
    
</script>

<style scoped>
.card{
    background: #FFFFFF;
    box-shadow: 6.25px 12.5px 100px 10px rgba(8, 30, 85, 0.25);
    border-radius: 20px;
    border: none;
}
.p{
    color: rgba(8, 30, 85, 1);
    text-decoration: inherit;
    font-size: 11px;
}
.pactive{
    color: #D72483;
    text-decoration: inherit;
    font-size: 13px;
}
</style>