<template>
    <b-container style="height: 100%;">
        <h1>{{$t('Pricing.title')}}<b>{{$t('Pricing.boldtitle')}}</b></h1>
            <b-row>
                <b-col>
                    <center class="rect">
                        <b-img style="max-height:360px" :src='require("../assets/Phone_money.svg")' fluid alt="Responsive image"/>
                    </center>
                </b-col>
                <b-col class="d-lg-block">
                    <div style="margin-top: 30%; max-width: 33ch;">
                        <p style="font-size: 20px">{{$t('Pricing.text')}}</p>
                        <router-link :to="'/access'" style="text-decoration: inherit"><b-button variant="primary" block>{{$t('Pricing.signup')}}</b-button></router-link>              
                    </div>
                </b-col>
            </b-row>
    </b-container>
</template>

<script>

export default {
    name: "Picing",
    components:{}
}
</script>

<style lang="scss">
.textin{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}
.placeholder{
    height: 50px;
}

@media only screen and (max-width: 992px) {
  .placeholder{
      height: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .placeholder{
      height: 30px;
  }
}
</style>
