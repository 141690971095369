
<script>
import { Radar } from 'vue-chartjs'

export default {
    extends: Radar,
    props: ['datasets', 'options','tdata','topic'],
    watch: {
        'tdata' () {
            this.render(0)
        },
        'topic' () {
            this.render(0)
        },
    },
    mounted () {
        this.render(1000)
    },
    methods: {
        render(time){
            this.renderChart({
            labels: [this.topic[0],this.topic[1],this.topic[2]],
            datasets: [{
                    label: this.$t('App.Student'),
                    fill: true,
                    backgroundColor: 'rgba(215,36,131,0.2)',
                    borderColor: 'rgba(215,36,131,1)',
                    pointBorderColor: 'rgba(215,36,131,1)',
                    pointBackgroundColor: 'rgba(215,36,131,1)',
                    data: [3,3.2,4.5]
            },{
                    label: this.$t('App.Teacher'),
                    fill: true,
                    backgroundColor: 'rgba(8,30,85,0.2)',
                    borderColor: 'rgba(8,30,85,1)',
                    pointBorderColor: 'rgba(8,30,85,1)',
                    pointBackgroundColor: 'rgba(8,30,85,1)',
                    data: [(2.25+this.tdata/4),3,3.25]
                    
            }],
        },{
            tooltips: false,
            responsive: true,
            maintainAspectRatio: true,
            layout: {
                padding:{
                    left: 10,
                    bottom: -50
                }
            },
            /* scale: https://www.chartjs.org/docs/latest/axes/radial/linear.html#axis-range-settings */
            scale: {
                angleLines: {
                    display: true
                },
                pointLabels: {
                    fontSize: 16
                },
                ticks: {
            /* https://www.chartjs.org/docs/latest/axes/styling.html#tick-configuration */
            /* suggestedMax and suggestedMin settings only change the data values that are used to scale the axis */
                    min: 0,
                    max: 5,
                    stepSize: 0.1, /* 25 - 50 - 75 - 100 */
                    maxTicksLimit: 1, /* Or use maximum number of ticks and gridlines to show */
                    display: false, // remove label text only,
                }
            },
            legend: {
            /* https://www.chartjs.org/docs/latest/configuration/legend.html */
                display: true,
                labels: {
                    padding: 10,
                    fontSize: 14,
                    lineHeight: 20,
                }
            },
            elements:{
               radius: 5
            },
            animation: {
                duration: time
            }
        })
        }
    }

}   
</script>