<template>
        <form @submit.prevent="handleSubmit" ref="form">
            <div class="form-group">
                <b-row style="margin-bottom: -5px">
                    <b-col md="7" style="padding-top: 10px">
                            <input :placeholder="$t('input.mail')" v-model="userForm.email" id="email" name="email" class="form-control" style="background-color: rgba(8, 30, 85, 0.1); border-width: 0px;"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }" />
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">{{ $t('input.error.mail.required') }}</span>
                            <span v-if="!$v.userForm.email.email">{{ $t('input.error.mail.valid') }}</span>
                        </div>
                    </b-col>
                    <b-col md="5" style="padding-top: 10px">
                        <div class="form-group">
                            <button class="btn btn-primary btn-block">{{ $t('input.mailbutton') }}</button>
                        </div>
                    </b-col>
                </b-row>
                <center v-if="message.spam">{{ $t('input.Spam') }}</center>
                <center v-if="message.sending">{{ $t('input.sendingmail') }}</center>
                <center v-if="message.error">{{ $t('input.funcerror') }}<a href="mailto:felix@heilinfe.de">felix@heilinfe.de</a></center>
                <center v-if="message.send"><li class="check">{{ $t('input.sendmail') }}</li></center>
            </div>
        </form>
</template>

<script>
    import { required, email } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        data() {
            return {
                message:{
                    spam: true,
                    sending: false,
                    send: false,
                    error: false
                },
                userForm: {
                    email: ''
                },
                isSubmitted: false
            };
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                }
            }
        },
        methods: {
            checkresponse (response) {
                if (response.status==200){
                    this.message.sending = false;
                    this.message.send = true;
                }
                else{
                    this.message.sending = false;
                    this.message.error = true;
                }
            },
            handleSubmit() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.message.spam = false;
                this.message.send = false;
                this.message.error = false;
                this.message.sending = true;
                axios.get('https://learnlog-landing-back.azurewebsites.net/api/inputForm',{
                    params:{
                        email: this.userForm.email,
                        lang: this.$i18n.locale
                    }
                },{ "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"}).then(( response )=>{
                    setTimeout(() => { this.checkresponse(response) }, 10);
                 }, (error) => {
                    setTimeout(() => { this.checkresponse(error) }, 300);
                 });
            }
        },
        watch: {
            isSubmitted() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.userForm.email = ""
                this.$v.$reset()
            }
        }
    };
</script>

<style lang="scss">
.check{
    margin-top: -20px;
    list-style-image: url('../assets/check.svg');
}
.form-group > label {
    font-weight: 600;
}

.textin{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}
</style>
