import Vue from 'vue'
import Router from 'vue-router'
import home from './home'
import access from './access'
import resources from './resources'
import pricing from './pricing'
import about from './about'
import notfound from './404'
import imprint from './imprint'
import privacypolicy from './privacypolicy'

Vue.use(Router)

export default new Router({
  routes: [{
          path: '/',
          name: 'home',
          component: home
        },{
          path: '/access',
          name: 'access',
          component: access
        },{ 
          path: '/resources',
          name: 'resources',
          component: resources
        },{ 
          path: '/pricing',
          name: 'pricing',
          component: pricing
        },{ 
          path: '/about',
          name: 'about',
          component: about
        },{
          path: '/imprint',
          name: 'imprint',
          component: imprint
        },{
          path: '/privacypolicy',
          name: 'privacy policy',
          component: privacypolicy
        },{
          path: '*',
          name: '404',
          component: notfound
       
    }
  ]
})