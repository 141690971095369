<template>
    <b-container style="height: 100%;">
            <b-row>
                <b-col>
                    <h1>{{$t('Register.title')}}<b>{{$t('Register.boldtitle')}}</b></h1>
                <RegisterForm/>
                </b-col>
                <b-col class="d-none d-lg-block">
                    <div class="align-items-center"></div>
                    <div class="rect">
                        <b-img style="max-height: 380px" :src='require("../assets/phones.svg")' fluid alt="Responsive image"/>
                    </div>
                </b-col>
            </b-row>
    </b-container>
</template>

<script>
import RegisterForm from './Register_Form'

export default {
    name: "Hero",
    components:{ RegisterForm }
}
</script>

<style lang="scss">
.form-group > label {
    font-weight: 600;
}

.textin{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}
</style>
