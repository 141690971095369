<template>
    <b-container>
        <div class="placeholder"/>
        <h1>{{$t('App.try')}}</h1>
        <CardG/>
        <div class="placeholder"/>
        <div class="placeholder"/>
        <CardR/>
        <div class="placeholder"/>
        <div class="placeholder"/>
        <CardF/>
        <div class="placeholder"/>
        <div class="placeholder"/>
        <center>
          <h1>{{ $t('Slogan') }}</h1>
          <router-link :to="'/access'" style="text-decoration: inherit">{{ $t('EarlyaccessLink') }}&rarr;</router-link>
        </center>
        <div class="placeholder"/>
    </b-container>
</template>

<script>
import CardG from './Card_G'
import CardR from './Card_R'
import CardF from './Card_F'

export default {
    name: 'Body',
    components: {
    CardG,
    CardR,
    CardF
  }
}  
</script>

<style scoped>
.placeholder{
    height: 50px;
}

@media only screen and (max-width: 992px) {
  .placeholder{
      height: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .placeholder{
      height: 30px;
  }
}
</style>