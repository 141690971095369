<template>
    <b-card>
        <h4>{{$t('App.setup.title.title')}}</h4>
        <b-row>
            <b-col>
                {{$t('App.setup.title.subject')}}
                <b-input v-model.lazy="subject" type="text" placeholder="Subject" class="textin" no-resize>
                </b-input>
            </b-col>
            <b-col>
                {{$t('App.setup.title.class')}}
                <b-input v-model="klass" type="text" placeholder="Class" class="textin" no-resize>
                </b-input>
            </b-col>
        </b-row>
        <hr/>
         <b-row no-gutters>
            <b-col class="input" fluid="lg">
                {{$t('App.setup.title.Topic',{num: 1})}}
                <b-input v-model="topicOne" v-on:change="() => this.$store.commit('settopicOne', this.topicOne)" type="text" rows="3" :placeholder="$t('App.setup.placeholder.firsttopic')" class="textin" maxlength="16" no-resize>
               </b-input>
               <div class="placeholder"/>
                {{$t('App.setup.title.Explenation',{num: 1})}}
                <b-textarea v-model="explanationOne" v-on:change="() => this.$store.commit('setexplanationOne', this.explanationOne)" type="text" rows="3" :placeholder="$t('App.setup.placeholder.firstexplenation')" class="textin">
                </b-textarea>
            </b-col>
            <div class="topics"/>
            <b-col class="input" fluid="lg">
                {{$t('App.setup.title.Topic',{num: 2})}}
                <b-input v-model="topicTwo" v-on:change="() => this.$store.commit('settopicTwo', this.topicTwo)" type="text" :placeholder="$t('App.setup.placeholder.secondtopic')" class="textin" maxlength="16" no-resize>
                </b-input>
                <div class="placeholder"/>
                {{$t('App.setup.title.Explenation',{num: 2})}}
                <b-textarea v-model="explanationTwo" v-on:change="() => this.$store.commit('setexplanationTwo', this.explanationTwo)" type="text" rows="3" :placeholder="$t('App.setup.placeholder.secondexplenation')" class="textin">
                </b-textarea>
            </b-col>
            <div class="topics"/>
            <b-col class="input" fluid="lg">
                {{$t('App.setup.title.Topic',{num: 3})}}
                <b-input v-model="topicThree" v-on:change="() => this.$store.commit('settopicThree', this.topicThree)" type="text" :placeholder="$t('App.setup.placeholder.thirdtopic')"  class="textin" maxlength="16" no-resize>
                </b-input>
                <div class="placeholder"/>
                {{$t('App.setup.title.Explenation',{num: 3})}}
                <b-textarea v-model="explanationThree" v-on:change="() => this.$store.commit('setexplanationThree', this.explanationThree)" type="text" rows="3" :placeholder="$t('App.setup.placeholder.thirdexplenation')" class="textin">
                </b-textarea>
            </b-col>
        </b-row>
        <div class="placeholder"/>
    </b-card>

</template>

<script>
export default {
    name: "Setup",
    data: () => ({
        subject: '',
        klass: '8a',
        topicOne: '',
        explanationOne: '',
        topicTwo: '',
        explanationTwo: '',
        topicThree: '',
        explanationThree: ''
 }),
 watch:{
     "$store.getters.gettopicOne": function(){
        this.subject = this.$t('App.class')
        this.topicOne = this.$store.getters.gettopicOne
        this.explanationOne = this.$store.getters.getexplanationOne
        this.topicTwo = this.$store.getters.gettopicTwo
        this.explanationTwo = this.$store.getters.getexplanationTwo
        this.topicThree = this.$store.getters.gettopicThree
        this.explanationThree = this.$store.getters.getexplanationThree
     }
 },
 computed: {
  class: function () {
    return this.$t('App.class')
  },
  ctopicOne: function () {
    this.$store.commit('settopicOne', this.$t('App.topicOne'))
    return this.$t('App.topicOne')
  },
  cexplenationOne: function () {
    this.$store.commit('setexplanationOne', this.$t('App.explanationOne'))
    return this.$t('App.explanationOne')
  },
  ctopicTwo: function () {
    this.$store.commit('settopicTwo', this.$t('App.topicTwo'))
    return this.$t('App.topicTwo')
  },
  cexplenationTwo: function () {
    this.$store.commit('setexplanationTwo', this.$t('App.explanationTwo'))
    return this.$t('App.explanationTwo')
  },
  ctopicThree: function () {
    this.$store.commit('settopicThree', this.$t('App.topicThree'))
    return this.$t('App.topicThree')
  },
  cexplenationThree: function () {
    this.$store.commit('setexplanationThree', this.$t('App.explanationThree'))
    return this.$t('App.explanationThree')
  },
  
 },
  mounted(){
    this.subject = this.$t('App.class')
    this.topicOne = this.$store.getters.gettopicOne
    this.explanationOne = this.$store.getters.getexplanationOne
    this.topicTwo = this.$store.getters.gettopicTwo
    this.explanationTwo = this.$store.getters.getexplanationTwo
    this.topicThree = this.$store.getters.gettopicThree
    this.explanationThree = this.$store.getters.getexplanationThree
 }
}
</script>

<style scoped>
hr{
    border: 1px solid rgba(215,36,131,0.4);
    border-radius: 5px;
}
.card{
    background: #FFFFFF;
    box-shadow: 6.25px 12.5px 100px 10px rgba(8, 30, 85, 0.25);
    border-radius: 20px;
    border: none;
}
.input{
    min-width: 140px;
}
.textin{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}
.textin:active{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}

.topics{
    width: 5px;
    height: 25px;
}

.placeholder{
    height: 10px;
}
</style>