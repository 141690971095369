
<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['tdata','filldata','teacherdata'],
  mounted () {
      this.render(1000)
  },
    watch: {
        'tdata' () {
            this.render(0)
        },
        'filldata' () {
            this.render(1000)
        },
    },
  methods: {
    render (time) {
      this.renderChart({
            labels: ['one','two','three','four'],
            datasets: [
            {
            label: this.$t('App.Student'),
            backgroundColor: 'rgba(215,36,131,0.2)',
            borderColor: 'rgba(215,36,131,1)',
            pointBorderColor: 'rgba(215,36,131,1)',
            pointBackgroundColor: 'rgba(215,36,131,1)',
            data: this.filldata
            },
            {
            label: this.$t('App.Teacher'),
            backgroundColor: 'rgba(8,30,85,0.2)',
            borderColor: 'rgba(8,30,85,1)',
            pointBorderColor: 'rgba(8,30,85,1)',
            pointBackgroundColor: 'rgba(8,30,85,1)',
            data: this.teacherdata.slice(0,4)
            
            }
        ]
        },{
            aspectRatio: 2,
            tooltips: false,
            layout: {
                padding:{
                    left: 60,
                    right: 50,
                    bottom: 80
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: 5,
                        maxTicksLimit: 1,
                    }
                }],
                xAxes: [{
                    ticks: {
                        display: false,
                        maxTicksLimit: 1
                    }
                }]
            },
            legend: {
            /* https://www.chartjs.org/docs/latest/configuration/legend.html */
                display: true,
                labels: {
                    padding: 10,
                    fontSize: 14,
                    lineHeight: 20,
                }
            },
            animation: {
                duration: time
            }
        })
    }
}
}
</script>