<template>
    <b-card>
            <div style="width=600px" />
            <div style="align-content: center; text-align: center;">
                    <h1 id="title">{{this.$store.getters.gettopicOne}}<sup id=quest><img src="../assets/quest.svg" width="30" style="cursor: help;"/></sup></h1>
                    <b-tooltip target="quest" placement="bottom" boundary-padding="40" triggers="hover">{{this.$store.getters.getexplanationOne}}</b-tooltip>
                    <div style="display: flex; flex-direction: roll; justify-content: space-between; width">
                        <img id="cry" v-bind:src="getpic('cry')" :style="getshade(value,1)" />
                        <img id="sad" v-bind:src="getpic('sad')" :style="getshade(value,2)" />
                        <img id="mid" v-bind:src="getpic('mid')" :style="getshade(value,3)" />
                        <img id="happy" v-bind:src="getpic('happy')" :style="getshade(value,4)" />
                        <img id="best" v-bind:src="getpic('best')" :style="getshade(value,5)" />
                    </div>
                    </div>
                <div style="align-content: center; text-align: center;">
                    <b-input class="slider" v-on:change="() => this.$store.commit('settdata', this.value)" id="range-1" v-model="value" type="range" min="1" max="5" thumb-label="always" ></b-input> 
            </div>
    </b-card>
</template>

<script>
export default {
    name: "Slider",
    data: () => ({
        value: '4',
        topicOne: '',
        explanationOne: '',
        tdata: ''
    }),
    methods: {
        getshade(value,id){
            var style = ['opacity: 100%; width: 20%','opacity: 40%; width: 20%','opacity: 30%; width: 20%','opacity: 20%; width: 20%','opacity: 10%; width: 20%'];
            return style[Math.abs(value-id)]
        },
        getpic(pic){
            return require("../assets/"+pic+".svg")
        }
    }
}
</script>

<style scoped>
.card{
    background: #FFFFFF;
    box-shadow: 6.25px 12.5px 100px 10px rgba(8, 30, 85, 0.25);;
    border-radius: 20px;
    border: none;
}
.slider {
    width: 80%;
}
.slider::-webkit-slider-runnable-track{
    background: rgba(8, 30, 85, 1);
}
</style>