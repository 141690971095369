<template>
    <b-container style="height: 100%;">
         <h1>{{$t('About.title')}}<b>{{$t('About.boldtitle')}}</b></h1>
               <b-row>
                <b-col>
                    <div class="align-items-center" style="margin-top: 50px">
                        <h2>{{$t('About.valuetitle')}}</h2>
                        <p>{{$t('About.value')}}</p>
                        <h2>{{$t('About.storytitle')}}</h2>
                        <p>{{$t('About.story')}}</p>

                    </div>
                </b-col>
                <b-col md>
                    <div class="align-items-center" style="text-align: center;"></div>
                    <center>
                        <b-img style="min-width: 300px; margin-bottom: 30px" :src='require("../assets/logo.svg")' fluid alt="Responsive image"/>
                    </center>
                    <h2>{{$t('About.texttitle')}}</h2>
                    <p>{{$t('About.text')}}</p>
                </b-col>
            </b-row>    
            <center>
                <div style="max-width: 600px; margin-top: 10px">
                    <router-link :to="'/access'" style="text-decoration: inherit;">
                        <b-button variant="primary" block>
                            {{$t('About.button')}}
                        </b-button>
                    </router-link>
                </div>
            </center> 
    </b-container>
</template>

<script>

export default {
    name: "Hero"
}
</script>

<style lang="scss">
</style>

