<template>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <b-row>
                    <b-col cols="5">
                          <input :placeholder="$t('input.name')" v-model="userForm.name" name="email" class="form-control" style="background-color: rgba(8, 30, 85, 0.1); border-width: 0px;"
                            :class="{ 'is-invalid': isSubmitted && $v.userForm.name.$error }" />
                            <div v-if="isSubmitted && $v.userForm.name.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.name.required">{{$t('input.error.name.required')}}</span>
                </div>
                    </b-col>
                    <b-col cols="7">
                            <input :placeholder="$t('input.mail')" v-model="userForm.email" id="email" name="email" class="form-control" style="background-color: rgba(8, 30, 85, 0.1); border-width: 0px;"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }" />
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">{{$t('input.error.mail.required')}}</span>
                            <span v-if="!$v.userForm.email.email">{{$t('input.error.mail.valid')}}</span>
                        </div>
                        <div v-else class="placeholder"></div>
                    </b-col>
                </b-row>
                <b-textarea v-model="userForm.message" type="text" rows="5" :placeholder="$t('input.message')" class="textin" no-resize :class="{ 'is-invalid': isSubmitted && $v.userForm.message.$error }" ></b-textarea>
                <div v-if="isSubmitted && $v.userForm.message.$error" class="invalid-feedback">
                        <span v-if="!$v.userForm.message.required">{{$t('input.error.message.required')}}</span>  
                </div>
                <div v-else class="placeholder"></div>
                <div class="form-group">
                    <button class="btn btn-primary btn-block">{{$t('input.mailbutton')}}</button>
                </div>
                <center v-if="message.spam">{{$t('Register.back')}}</center>
                <center v-if="message.sending">{{ $t('input.sendingrequest') }}</center>
                <center v-if="message.error">{{ $t('input.funcerror') }}<a href="mailto:felix@heilinfe.de">felix@heilinfe.de</a></center>
                <center v-if="message.send"><li class="check">{{ $t('input.sendrequest') }}</li></center>
    
            </div>
        </form>
</template>

<script>
    import { required, email } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        data() {
            return {
                message:{
                    spam: true,
                    sending: false,
                    send: false,
                    error: false
                },
                userForm: {
                    email: '',
                    name: '',
                    message: '',
                },
                isSubmitted: false
            };
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                name:{
                    required
                },
                message:{
                    required
                }
            }
        },
        methods: {
            checkresponse (response) {
                if (response.status==200){
                    this.message.sending = false;
                    this.message.send = true;
                }
                else{
                    this.message.sending = false;
                    this.message.error = true;
                }
            },
            handleSubmit() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.message.spam = false;
                this.message.send = false;
                this.message.error = false;
                this.message.sending = true;
                axios.get('https://learnlog-landing-back.azurewebsites.net/api/emailForm',{
                    params:{
                        email: this.userForm.email,
                        name: this.userForm.name,
                        message: this.userForm.message,
                        lang: this.$i18n.locale
                    }
                },{ "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"}).then(( response )=>{
                    setTimeout(() => { this.checkresponse(response) }, 10);
                 }, (error) => {
                    setTimeout(() => { this.checkresponse(error) }, 300);
                 });
            }
        },
        watch: {
            isSubmitted() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.userForm.email = ""
                this.userForm.name = ""
                this.userForm.message = ""
                this.$v.$reset()
            }
        }
    };
</script>

<style lang="scss">
.placeholder{
    height: 5px;
}

.form-group > label {
    font-weight: 600;
}

.textin{
    background-color: rgba(8, 30, 85, 0.1);
    border-width: 0px;
}
</style>
