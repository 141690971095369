<template>
    <b-container style="height: 100%;">
         <h1>{{$t('Ressources.title')}}<b>{{$t('Ressources.boldtitle')}}</b></h1>
               <b-row>
                <b-col>
                    <div class="align-items-center">
                        <b-img style="max-height: 360px" :src='require("../assets/phones.svg")' fluid alt="Responsive image"/>
                    </div>
                </b-col>
                <b-col md>
                    <div class="align-items-center" style="text-align: center;"></div>
                    <h2>{{$t('Ressources.texttitle')}}</h2>
                    <p style="max-width: 40ch;">{{$t('Ressources.text')}}</p>
                </b-col>
            </b-row>      
    </b-container>
</template>

<script>

export default {
    name: "Hero"
}
</script>

<style lang="scss">
</style>
