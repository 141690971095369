<template>
    <div>
        <b-container>
            <hr/>
            <b-row style="height: 50px">
                <b-col cols="4" md="5">
                    <img src='../assets/logo_dark.svg' alt="logo" height="30px"/>
                </b-col>
                <b-col cols="2" md="3">
                    <router-link :to="'/privacypolicy'" class="flink">{{ $t('Footer.items.PrivacyPolicy') }}</router-link>
                </b-col>
                <b-col cols="2">
                    <router-link :to="'/imprint'" class="flink">{{ $t('Footer.items.Imprint') }}</router-link>
                </b-col>
                <b-col>
                    <SwitchLanguage/>
                </b-col>
            </b-row>
            <div class="placeholder"></div>
        </b-container>
    </div>
</template>

<script>
import SwitchLanguage from './SwitchLanguage'

export default {
    name: 'Footer',
    components:{
        SwitchLanguage 
    }
}
</script>

<style scoped>
.flink{
    color: rgba(8, 30, 85, 0.6);
    text-decoration: none;
}
.flink:hover{
    color: rgba(8, 30, 85, 0.8);
    text-decoration: none;
}
hr{
    border: 1px solid rgba(8, 30, 85, 0.8);
    border-radius: 2px;
}

.placeholder{
    height: 30px;
}

@media only screen and (max-width: 992px) {
  .placeholder{
      height: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .placeholder{
      height: 20px;
  }
}
</style>