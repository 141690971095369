<template>
  <div class="langswitch">
    <b-select v-model="$i18n.locale" v-on:change="presets()">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang" >
          {{ lang }}
      </option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: 'SwitchLocale',
  data() {
    return { langs: ['en', 'de'] }
  },
  methods:{
    presets(){
      if (this.$i18n.locale=='de'){
        if ( this.$store.getters.gettopicOne== 'Preperation'){
          this.$store.commit('settopicOne', 'Vorbereitung') 
        }
        if (this.$store.getters.getexplanationOne== 'Have you been prepared for the last lesson?'){
          this.$store.commit('setexplanationOne', 'Warst du auf die letzte Stunde vorbereitet?')
        }
        if (this.$store.getters.gettopicTwo== 'Particpation'){
          this.$store.commit('settopicTwo', 'Beteiligung')
        }
        if (this.$store.getters.getexplanationTwo== 'Did you add something relevant to the lesson?'){
          this.$store.commit('setexplanationTwo', 'Hast du etwas Relevantes beigetragen?')
        }
        if (this.$store.getters.gettopicThree== 'Content'){
          this.$store.commit('settopicThree', 'Inhalt')
        }
        if (this.$store.getters.getexplanationThree== 'Have you learned something this lesson?'){
          this.$store.commit('setexplanationThree', 'Hast du diese Stunde etwas gelernt?')
        }
      } else if(this.$i18n.locale=='en'){
        if (this.$store.getters.gettopicOne== 'Vorbereitung'){
          this.$store.commit('settopicOne', 'Preperation')
        }
        if (this.$store.getters.getexplanationOne== 'Warst du auf die letzte Stunde vorbereitet?'){
          this.$store.commit('setexplanationOne', 'Have you been prepared for the last lesson?')
        }
        if (this.$store.getters.gettopicTwo== 'Inhalt'){
          this.$store.commit('settopicTwo', 'Content')
        }
        if (this.$store.getters.getexplanationTwo== 'Hast du diese Stunde etwas gelernt?'){
          this.$store.commit('setexplanationTwo', 'Did you add something relevant to the lesson?')
        }
        if (this.$store.getters.gettopicThree== 'Beteiligung'){
          this.$store.commit('settopicThree', 'Particpation')
        }
        if (this.$store.getters.getexplanationThree== 'Hast du etwas Relevantes beigetragen?'){
          this.$store.commit('setexplanationThree', 'Have you learned something this lesson?')
        }
      }
    }
  },
  mounted(){
    this.presets()
  }
}
</script>

<style>
.langswitch {
    border-color: rgba(8, 30, 85, 0.1);
    width: 60px;
}
</style>