<template>
  <b-navbar toggleable="md" fixed="top" sticky type="dark" class="nav m-auto">
    <b-container>
      <router-link :to="'/'">
      <b-navbar-brand >
      <img src='../assets/logo.svg' alt="logo" height="30px"/>
      </b-navbar-brand>
      </router-link>
    <b-navbar-toggle target="navbar-toggle-collapse">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon v-else icon="chevron-bar-down"></b-icon>
      </template>
    </b-navbar-toggle>
    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/about">{{ $t('Header.navitems.About') }}</b-nav-item>
        <b-nav-item to="/resources">{{ $t('Header.navitems.Resources') }}</b-nav-item>
        <b-nav-item to="/pricing">{{ $t('Header.navitems.Pricing') }}</b-nav-item>
        <div class="placeholder"/>
        <router-link :to="'/access'" style="text-decoration: inherit"><b-button variant="primary" block>{{$t('Header.navitems.Signup')}}</b-button></router-link>
      </b-navbar-nav>
    </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped>
.nav{
  background: rgba(8, 30, 85, 0.8);
  backdrop-filter: blur(4px);
  white-space: nowrap;
}
.navbar-toggler {
    border: none;
    background-color:rgb(215, 36, 131);
    width: 50px;
    height: 40px;
}
.nav-item.nav-item.nav-item a {
  color: rgba(255, 255, 255, 0.6);
}
.nav-item.nav-item.nav-item a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.placeholder{
  width: 50px;
  height: 0px;
}
</style>

