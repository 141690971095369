
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        topicOne: 'Preperation',
        explanationOne: 'Have you been prepared for the last lesson?',
        topicTwo: 'Particpation',
        explanationTwo: 'Did you add something relevant to the lesson?',
        topicThree: 'Content',
        explanationThree: 'Have you learned something this lesson?',
        tdata: '4'
    },
    mutations:{
        settopicOne (state, payload) {
            state.topicOne = payload;
        },
        settopicTwo (state, payload) {
            state.topicTwo = payload;
        },
        settopicThree (state, payload) {
            state.topicThree = payload;
        },
        setexplanationOne (state, payload) {
            state.explanationOne = payload;
        },
        setexplanationTwo (state, payload) {
            state.explanationTwo = payload;
        },
        setexplanationThree (state, payload) {
            state.explanationThree = payload;
        },
        settdata (state, payload){
            state.tdata = payload;
        }
    },
    getters:{
        gettopicOne: state => state.topicOne,
        getexplanationOne: state => state.explanationOne,
        gettopicTwo: state => state.topicTwo,
        getexplanationTwo: state => state.explanationTwo,
        gettopicThree: state => state.topicThree,
        getexplanationThree: state => state.explanationThree,
        gettdata: state => state.tdata
    }
})