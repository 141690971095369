<template>
  <div id="app">
     <Header/>
     <Hero/>
     <Body/>
     <Footer/>
  </div>
</template>

<script>
import Header from '../components/Header'
import Hero from '../components/Hero'
import Body from '../components/Body'
import Footer from '../components/Footer'

export default {
  name: 'home',
  components: {
    Header,
    Hero,
    Body,
    Footer
  }
}
</script>

<style>

#app {
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
}
</style>