<template>
<div class="hero">
    <b-container>
        <div class="placeholder"/>
        <b-row>
            <b-col>
                <h1 style="font-size: 50px">{{ $t('Hero.title') }}<b>{{ $t('Hero.boldtitle') }}</b></h1>
                <div class="placeholder"></div>
                <p style="max-width: 40ch; font-size: 20px">{{ $t('Hero.subtitle') }}</p>
                <div class="placeholder"></div>
                <Input/>
            </b-col>
            <b-col class="d-none d-lg-block">
                <div class="align-items-center"></div>
                <div class="rect">
                    <b-img :src='require("../assets/phones.svg")' fluid alt="Responsive image"/>
                </div>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import Input from './Input'

export default {
    name: "Hero",
    components:{ 
        Input
        }
}
</script>

<style scoped>
.hero{
    background-color:rgba(8, 30, 85, 0.2);
    border-style: solid;
    border-width: 50px;
    border-color: transparent;
}

.placeholder{
    height: 50px;
}

@media only screen and (max-width: 992px) {
    .placeholder{
        height: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .placeholder{
        height: 30px;
    }
}
</style>